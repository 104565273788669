<template>
    <div>
        <pull-to :top-load-method="refresh" :top-config="TOP_DEFAULT_CONFIG" :is-bottom-bounce="false"
            :is-top-bounce="on_top">
            <b-card no-body class="mb-0">
                <b-card-header>
                    <b-card-title>Download Log List</b-card-title>
                    <feather-icon icon="ActivityIcon" size="15" class="cursor-pointer" />
                </b-card-header>
                <div class="m-2">
                    <b-row>
                        <b-col cols="12" md="8" mx="11"
                            class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                            <label>Show</label>
                            <v-select v-model="perPage" :options="perPageOptions" :clearable="false"
                                class="per-page-selector d-inline-block mx-50 ml-1" />
                        </b-col>
                        <b-col cols="12" md="4" mx="1">
                            <b-row class="align-items-center">
                                <b-col cols="12" md="12" class="mb-md-0 mb-2">
                                    <b-form-input v-model="searchQuery" class="d-inline-block mr-1"
                                        placeholder="Search..." />
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </div>
                <b-table ref="refLogListTable" class="position-relative" :items="fetchLogs" responsive
                    :table-class="'build-list-table table dataTable no-footer dtr-column'" striped
                    :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty
                    empty-text="No matching records found" :sort-desc.sync="isSortDirDesc">
                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner class="d-block mx-auto mb-50"></b-spinner>
                            <strong>Loading...</strong>
                        </div>
                    </template>
                    <template #cell(id)="data">
                        <span class="align-text-top text-capitalize">{{ data.item.id }}</span>
                    </template>

                    <template #cell(os)="data">
                        <social-icons :socialType="data.item.os" :size="'16'"></social-icons>
                    </template>
                    <template #cell(name)="data">
                        <div class="d-flex align-items-center">
                            <b-avatar rounded size="20" class="mr-50"
                                :src="data.item.icon?backend_url+data.item.icon:''"
                                :text="avatarText(data.item.name)" />
                            <span class="align-text-top text-capitalize">{{ data.item.name }}</span>
                        </div>

                    </template>

                    <template #cell(user)="data">
                        <span v-if="data.item.user" class="align-text-top text-capitalize">
                            {{ data.item.user.name }}
                        </span>
                        <span v-else class="align-text-top text-capitalize">-</span>
                    </template>
                    <template #cell(device)="data">
                        <span class="align-text-top text-capitalize">{{ getDeviceAndVersion(data.item.device)}}</span>
                    </template>
                    <template #cell(created_at)="data">
                        <span class="align-text-top text-capitalize">{{ dateFormat(data.item.created_at) }}</span>
                    </template>

                </b-table>

                <div class="mx-2 mb-2">
                    <b-row>
                        <b-col cols="12" sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-start">
                            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of
                            }}
                                entries</span>
                        </b-col>
                        <b-col cols="12" sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-end">
                            <b-pagination v-model="currentPage" :total-rows="totalData" :per-page="perPage" first-number
                                last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                                <template #prev-text>
                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                </template>
                                <template #next-text>
                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                </template>
                            </b-pagination>
                        </b-col>
                    </b-row>
                </div>
            </b-card>
        </pull-to>
    </div>
</template>

<script>
import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip, BSpinner, BCardTitle, BCardHeader,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useDownloadLogApi from '@/composables/useDownloadLogApi'
import useAppApi from '@/composables/useAppApi'
import moment from 'moment'
import vSelect from "vue-select";
import SocialIcons from '@/components/SocialIcons.vue'
import { avatarText } from '@core/utils/filter'
import PullTo from 'vue-pull-to'

export default {
    components: {
        BSpinner,
        BCard,
        BCardTitle,
        BCardHeader,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        vSelect,
        SocialIcons,
        PullTo
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    data() {
        return {
            backend_url: process.env.VUE_APP_BACKEND_URL,
            on_top: true,
        }
    },
    mounted() {
        window.addEventListener("scroll", () => { this.on_top = window.pageYOffset <= 50 })
    },
    setup() {
        const {
            TOP_DEFAULT_CONFIG
        } = useAppApi()

        const {
            fetchLogs,
            tableColumns,
            perPage,
            currentPage,
            totalData,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refLogListTable,
            refreshData,
        } = useDownloadLogApi()

        return {
            TOP_DEFAULT_CONFIG,
            fetchLogs,
            tableColumns,
            perPage,
            currentPage,
            totalData,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refLogListTable,
            refreshData,
            avatarText,
        }
    },
    methods: {
        refresh(loaded) {
            this.refreshData()
            loaded('done')
        },
        dateFormat(date) {
            return moment(date).format('YYYY-MM-DD HH:mm')
        },
        getDeviceAndVersion(agent) {
            let device = null
            let version = null
            if (/android/i.test(agent)) {
                device = 'android'
                const match = (agent).toLowerCase().match(/android\s([0-9\.]*)/i);
                version = match ? match[1] : null;
            }
            if (/iPad|iPhone|iPod/.test(agent) && !window.MSStream) {
                device = 'ios'
                var v = (agent).match(/OS (\d+)_(\d+)_?(\d+)?/)
                version = [parseInt(v[1], 10) + '.' + parseInt(v[2], 10) + '.' + parseInt(v[3] || 0, 10)]
            }
            if (device && version) return device + ' ' + version
            return agent
        },

    }
}

</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
