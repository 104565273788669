// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import store from '@/store'
import router from "@/router";

export default function useDownloadLogApi() {
    // Use toast
    const toast = useToast()
    // Table Handlers
    const refLogListTable = ref(null)
    const perPage = ref(localStorage.getItem('downloadLogPerPage') ?? 10)
    const totalData = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 15, 20, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const isLoading = ref(true)

    const tableColumns = [
        { key: 'id', sortable: true },
        { key: 'os', label: 'Os', sortable: false },
        { key: 'name', label: 'App', sortable: false },
        { key: 'version', sortable: false },
        { key: 'user', sortable: false },
        { key: 'device', sortable: false },
        { key: 'created_at', label: 'Downloaded At', sortable: true },
    ]

    const dataMeta = computed(() => {
        const localItemsCount = refLogListTable.value ? refLogListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalData.value,
        }
    })

    const refreshData = () => {
        localStorage.setItem('downloadLogPerPage', perPage.value)
        refLogListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery], () => {
        refreshData()
    })


    // ------------------------------------------------
    // Get Log List
    // ------------------------------------------------
    const fetchLogs = (ctx, callback) => {
        let sort = isSortDirDesc.value ? `-${sortBy.value}` : sortBy.value
        store
            .dispatch('downloadLogStore/fetchLogs', {
                sort: sort,
                page: currentPage.value,
                perPage: perPage.value,
                q: searchQuery.value,
                pagination: true
            })
            .then(response => {
                const { data, total } = response.data
                callback(data)
                totalData.value = response.data.pagination.total
            })
            .catch(error => {
                console.log(error)
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: 'Oops! Something went wrong!',
                    },
                })
            })
    }


    return {
        fetchLogs,
        tableColumns,
        perPage,
        currentPage,
        totalData,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refLogListTable,
        isLoading,
        refreshData,
    }
}
